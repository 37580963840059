/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --ninja-text-color: var(--slate12);
    --ninja-accent-color: var(--blue7);
    --ninja-secondary-background-color: var(--slate3);
    --ninja-secondary-text-color: var(--slate11);
    --ninja-selected-background: var(--blue4);
    --ninja-modal-background: var(--slate2);
    --ninja-group-text-color: var(--slate11);
    --ninja-placeholder-color: var(--slate11);
    --ninja-footer-background: var(--slate2);
    --ninja-z-index: 50;
  }

  .dark {
    --ninja-text-color: var(--slate12);
    --ninja-accent-color: var(--blue7);
    --ninja-secondary-background-color: var(--slate3);
    --ninja-secondary-text-color: var(--slate11);
    --ninja-selected-background: var(--blue4);
    --ninja-modal-background: var(--slate2);
    --ninja-group-text-color: var(--slate11);
    --ninja-placeholder-color: var(--slate11);
    --ninja-footer-background: var(--slate2);
    --ninja-z-index: 50;
  }

  .prose-strong {
    --tw-prose-body: var(--slate12) !important;
  }
}

/* Works on Firefox */
pre {
  scrollbar-color: theme("colors.radix.slate2") theme("colors.radix.slate6");
}

pre.torchlight {
  @apply !bg-radix-slate2;
}

pre.torchlight code {
  @apply block min-w-max;
}

pre.torchlight code .line-number,
pre.torchlight code .summary-caret {
  @apply mr-4;
}

/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar-track {
  @apply bg-radix-slate2;
}

::-webkit-scrollbar {
  @apply rounded-sm h-1 w-3;
}

::-webkit-scrollbar-thumb {
  @apply rounded-sm bg-radix-slate6 border-2 border-radix-slate8;
}

@layer utilities {
  .debug {
    @apply border red-border;
  }

  .bleed-bg {
    -o-border-image: linear-gradient(var(--slate2) 0 0) 0/ /0 100vw 0 100vw;
       border-image: linear-gradient(var(--slate2) 0 0) fill 0/ /0 100vw 0 100vw;
  }
}
